import { Injectable } from '@angular/core';
import { EmployeeApiService } from 'app/core/services/employee.api.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private modelService:EmployeeApiService) { }


  getTeleNotificationList():Observable<any>{
   return this.modelService.get('telecallerNotification/list')
  }
  getWoodExpertNotification():Observable<any>{
    return this.modelService.get('woodexpertNotification/list')
   }

  setReadStatus(key): Observable<any> {
    return this.modelService.post('readNotification', key);
  }
  setTeleReadStatus(key): Observable<any> {
    return this.modelService.post('telecaller/readNotification', key);
  }
  setWoodReadStatus(key): Observable<any> {
    return this.modelService.post('woodexpert/readNotification', key);
  }
}
