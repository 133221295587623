import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { saveToken, destroyToken, hasToken, decodedToken, clearSessionData } from '../helpers/token.helper';
import { User, IUserAPI } from '../models/';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { ApiAdapter } from './user/api.adapter.service';
import { get } from "lodash";
import { PORTALTYPE } from "../helpers/enum.helper";
import { constant } from '../helpers/global.helper';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isScrollToTop = new Subject();
  portalType = '';

  // settings routing restriction

  public isInSettingsMenu = new Subject();

  // sos notification

  public arrayOfSosSub = new Subject();

  //nomovement notification
  public arrayOfNomovementSub = new ReplaySubject(1000);

  // patient details notification

  public arrayOfPatientSub = new ReplaySubject(1000);


  constructor(
    private apiService: ApiService,
    private router: Router,
    private adapter: ApiAdapter,
  ) {

    this.isAuthenticatedSubject.next(hasToken());
    this.currentUserSubject.next(this.getSessionUser());

  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  signin(formData): Observable<IUserAPI> {

    return this.apiService.post('login', formData).pipe(map(response => this.adapter.adapt(response)));
  }

  otpVerify(formData): Observable<IUserAPI> {
    return this.apiService.post('login', formData).pipe(map(response => this.adapter.adapt(response)));
  }

  forgetpassword(formData): Observable<IUserAPI> {
    return this.apiService.post('forget', formData);
  }


  setAuth(user: User) {
    // Save JWT sent from server in localstorage
    saveToken(user.token);
    window.localStorage.setItem('sesUsr', JSON.stringify(user));
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    destroyToken();
    clearSessionData();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);

  }


  sendResetLink(model: User): Observable<IUserAPI> {
    let formData = new FormData();
    formData.append('email', model.email);
    return this.apiService.post('hospital/forget', formData).pipe(map(response => this.adapter.adapt(response)));
  }


  resetPassword(formData): Observable<any> {
    return this.apiService.post('reset', formData);
  }


  chagePassword(model: User): Observable<IUserAPI> {
    const formData = new FormData();
    const token = decodedToken();
    formData.append('old_password', model.oldPassword);
    formData.append('new_password', model.newPassword);
    formData.append('email', get(token, 'aud'));
    return this.apiService.post('changepassword', formData);
  }

  getCurrentUser(): User {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }


  get isLoggedIn() {
    return this.isAuthenticatedSubject.asObservable();
  }

  logout() {
    this.getPortalType();
    this.purgeAuth();
    this.goToLogin();
  }

  getSessionUser(): User {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }


  goToLogin() {
    if (this.portalType == PORTALTYPE.ADMIN) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.COMPANY) {
      this.router.navigate(['/company']); //default
    } else {
      this.router.navigate(['/']);
    }
  }



  getPortalType() {
    const token = decodedToken();
    this.portalType = get(token, 'portal') ?? '';
  }


  pushSosNotifies(sos) {
    this.arrayOfSosSub.next(sos)
  }


  encryptUsingAES256(plainText: any) {
    let _key = CryptoJS.enc.Utf8.parse(constant().app.secureKey);
    let _iv = CryptoJS.enc.Utf8.parse(constant().app.secureIV);
    let encryptData = CryptoJS.AES.encrypt(
      JSON.stringify(plainText), _key, {
      keySize: 128 / 8,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
    return encryptData ? encryptData : plainText;
  }

  decryptUsingAES256(plainText: any) {
    let _key = CryptoJS.enc.Utf8.parse(constant().app.secureKey);
    let _iv = CryptoJS.enc.Utf8.parse(constant().app.secureIV);
    let decryptData = CryptoJS.AES.decrypt(
      plainText, _key, {
      keySize: 128 / 8,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return decryptData ? JSON.parse(decryptData) : plainText;
  }

}
