<div class="text-center mat-bg-primary pt-1 pb-1">
  <h6 class="m-0">Notifications</h6>
</div>
<mat-nav-list class="notification-list" role="list">
  <!-- Notification item -->
  <mat-list-item *ngFor="let n of notifications | slice:0:15; let i = index" class="notific-item" role="listitem" routerLinkActive="open">
    <!-- <mat-icon class="notific-icon mr-1">notification_important</mat-icon> -->

    <img style="width: 50px;" src="{{n?.page == 'order_placed_online' ? 'assets/images/Order-Placed.png' : n?.page == 'payment_done_online' ? 'assets/images/Payment-Done.png' :
    n?.page == 'order_placed_cod' ? 'assets/images/Order-Placed.png' : n?.page == 'payment_done_cod' ? 'assets/images/Payment-Done.png' :
    n?.page == 'rating_review' ? 'assets/images/Rating-Review.png' : n?.page == 'notify_me' ? 'assets/images/Notify-Product.png' :
    n?.page == 'register' ? 'assets/images/New-Member-Registered.png' : 'assets/images/Youtube-Bell-Icon.png'}}" alt="">
    <a (click)="clicked(i,n)">
      <div class="mat-list-text">
        <h4 class="message">{{n.title}}</h4>
        <small class="time text-muted">{{ n.created_on ? (n.created_on | timeago:live) : ''}}</small>
      </div>
    </a>
  </mat-list-item>
</mat-nav-list>
                <!-- <div class="text-center mt-1" *ngIf="notifications?.length">
                  <small><a href="#" (click)="clearAll($event)">Clear all notifications</a></small>
                </div> -->

<div class="text-center mt-1" *ngIf=' count != 0'>
  <small><a (click)="viewMore()" style="color:blue;font-size: 14px;">View More</a></small>
</div>
<div class="text-center mt-1" *ngIf=' count == 0'>
  <small><a (click)="viewMore()" style="color:blue;font-size: 14px;">View List</a></small>
</div>

