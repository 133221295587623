<div class="header-topnav mat-elevation-z2">
  <div>
    <div class="topnav">
      <!-- App Logo -->
      <div class="topbar-branding">
        <img src="assets/images/logo.png" alt="" class="app-logo">
      </div>

      <ul class="menu" *ngIf="!layoutConf.isMobile">
        <li *ngFor="let item of menuItems; let i = index;">
          <div *ngIf="item.type !== 'separator'" routerLinkActive="open">
            <a matRipple routerLink="/{{getLink(item)}}" *ngIf="item.type === 'link'">
              <!-- <mat-icon>{{item.icon}}</mat-icon>  -->
              {{item.name | translate}}
            </a>
            <div *ngFor="let s of item.sub" style="display: none;">
              <a matRipple *ngIf="item.name == s.name" routerLink="/{{s.state}}"> {{s.name }}</a>
            </div>
            <div *ngIf="item.type === 'dropDown'">
              <label matRipple for="drop-{{i}}" class="toggle">
                <!-- <mat-icon>{{item.icon}}</mat-icon> -->
                {{item.name | translate}}
              </label>
              <a matRipple style="padding: 13px 15px;">
                <!-- <mat-icon>{{item.icon}}</mat-icon>  -->
                {{item.name | translate}}
              </a>
              <input type="checkbox" id="drop-{{i}}" />
              <ul>
                <li *ngFor="let itemLvL2 of item.sub; let j = index;" routerLinkActive="open">
                  <a matRipple routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}"
                    *ngIf="itemLvL2.type !== 'dropDown'">
                    <!-- <mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon>   -->
                    {{itemLvL2.name | translate}}
                  </a>

                  <div *ngIf="itemLvL2.type === 'dropDown'">
                    <label matRipple for="drop-{{i}}{{j}}" class="toggle">{{itemLvL2.name | translate}}</label>
                    <a matRipple>
                      <!-- <mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon>  -->
                      {{itemLvL2.name | translate}}
                    </a>
                    <input type="checkbox" id="drop-{{i}}{{j}}" />
                    <!-- Level 3 -->
                    <ul>
                      <li *ngFor="let itemLvL3 of itemLvL2.sub" routerLinkActive="open">
                        <a matRipple
                          routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}">
                          <!-- <mat-icon *ngIf="itemLvL3.icon">{{itemLvL3.icon}}</mat-icon> -->
                          {{itemLvL3.name | translate}}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
      <span fxFlex></span>
      <!-- End Navigation -->

      <!-- Top left user menu -->

     
      <button mat-button (click)='goToCalender()' *ngIf="portalType === checkType.EMPLOYEEE">
        <mat-icon>calendar_today</mat-icon>
      </button>
      <span class="pr-8">{{userName}}</span>
       <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right mr-1 img-button">
        <img src="{{image != null ? image : 'assets/images/avartar.jpg'}}" alt="">
      </button>
      <mat-menu #accountMenu="matMenu">
        <button mat-menu-item *ngIf="(portalType === checkType.ADMIN && permission.hasPermission(['Change Password'],['changepassword']))" [routerLink]="['/settings/change-password']">
          <mat-icon>lock</mat-icon>
          <span>Change Password</span>
        </button>
        <button mat-menu-item *ngIf="portalType === checkType.COMPANY"
          [routerLink]="['/company/settings/change-password']">
          <mat-icon>lock</mat-icon>
          <span>Change Password</span>
        </button>
        <button mat-menu-item (click)="logout(portalType)">
          <mat-icon>exit_to_app</mat-icon>
          <span>Sign out</span>
        </button>
      </mat-menu>
     <!-- <button mat-icon-button matTooltip="Notifications" (click)='toggleNotific()'
        class="topbar-button-right">
        <mat-icon>notifications_none</mat-icon>
        <span class="notification-number mat-bg-success">{{queueCount}}</span>
      </button> -->




      <button mat-icon-button matTooltip="Notifications" (click)="toggleNotific()"
      *ngIf="portalType === checkType.ADMIN && userType == 'admin' " [style.overflow]="'visible'"
      class="topbar-button-right">
      <mat-icon >notifications</mat-icon>
      <span class="notification-number mat-bg-success">{{ queueCount }}</span>
  </button>
  <!-- Dietitian Portal -->

  <button mat-icon-button matTooltip="Notifications" (click)="toggleNotific()"
      *ngIf="portalType === checkType.EMPLOYEEE" [style.overflow]="'visible'" class="topbar-button-right">
      <mat-icon >notifications</mat-icon>
      <span class="notification-number mat-bg-success">{{ queueCount }}</span>
  </button>
      <button mat-icon-button class="mr-1" (click)="toggleSidenav()" *ngIf="layoutConf.isMobile">
        <mat-icon>menu</mat-icon>
      </button>

    </div>
  </div>
</div>