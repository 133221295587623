import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { saveToken, destroyToken, hasToken, decodedToken, clearSessionData } from '../helpers/token.helper';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { get } from "lodash";
import { PORTALTYPE } from "../helpers/enum.helper";
import { Employee } from '../models/employee/employeee.model';
import { IEmployeeAPI } from '../models/employee/api.model';
import { EmployeeApiService } from './employee.api.service';
import { EmployeeApiAdapter } from './Employee/api.adapter.services';

@Injectable({
    providedIn: 'root'
})

export class EmployeeService {

    private currentUserSubject = new BehaviorSubject<Employee>({} as Employee);
    public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
    private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
    public isScrollToTop = new Subject();
    portalType = '';

    // settings routing restriction

    public isInSettingsMenu = new Subject();

    // sos notification

    public arrayOfSosSub = new Subject();

    //nomovement notification
    public arrayOfNomovementSub = new ReplaySubject(1000);

    // patient details notification

    public arrayOfPatientSub = new ReplaySubject(1000);

    constructor(
        private router: Router,
        private apiService: EmployeeApiService,
        private adapter: EmployeeApiAdapter
    ){
        this.isAuthenticatedSubject.next(hasToken());
        this.currentUserSubject.next(this.getSessionEmployee());
    }

    /******* SignIn *********/
    signin(formData): Observable<IEmployeeAPI> {
        return this.apiService.post('emp/login', formData).pipe(map(response => this.adapter.adapt(response)));
    }
    
    /******* otpVerify *********/
    otpVerify(formData): Observable<IEmployeeAPI> {
       return this.apiService.post('login', formData).pipe(map(response => this.adapter.adapt(response)));
    }

    /******* forgetpassword *********/
      forgetpassword(formData): Observable<IEmployeeAPI> {
        return this.apiService.post('forget', formData);
    }

    /******** set Auth *******/
    setAuth(employee: Employee) {
        // Save JWT sent from server in localstorage
        saveToken(employee.token);
        console.log(JSON.stringify(employee),"JSON.stringify(employee)--------")
        window.localStorage.setItem('sesUsr', JSON.stringify(employee));
        // Set current user data into observable
        this.currentUserSubject.next(employee);
         // Set isAuthenticated to true
        this.isAuthenticatedSubject.next(true);
    }

    /******* sendResetLink *********/
    sendResetLink(model: Employee): Observable<IEmployeeAPI> {
        let formData = new FormData();
        formData.append('email', model.email);
        return this.apiService.post('hospital/forget', formData).pipe(map(response => this.adapter.adapt(response)));
    }
    
    /******* resetPassword *********/
    resetPassword(formData): Observable<any> {
        return this.apiService.post('reset', formData);
    }

    /******* Logout *****/
    logout() {
        this.getPortalType();
        this.purgeAuth();
        this.goToLogin();
      }


    purgeAuth() {
        // Remove JWT from localstorage
        destroyToken();
        clearSessionData();
        // Set current user to an empty object
        this.currentUserSubject.next({} as Employee);
        // Set auth status to false
        this.isAuthenticatedSubject.next(false);
    
    }

    goToLogin() {
        if(this.portalType == PORTALTYPE.EMPLOYEEE){
          this.router.navigate(['/employee']);
        }
        else{
           this.router.navigate(['/']);
        }
    }

    getCurrentUser(): Employee {
        return JSON.parse(window.localStorage.getItem('sesUsr'));
    }

    /******** getSessionEmployee ********/
    getSessionEmployee(){
        return JSON.parse(window.localStorage.getItem('sesUsr'));
    }

    /********* Loggin Subject *********/
    get isLoggedIn() {
        return this.isAuthenticatedSubject.asObservable();
    }

    /******* Portal Type ********/
    getPortalType() {
        const token = decodedToken();
        this.portalType = get(token, 'portal') ?? '';
    }
    
    /******* Push Notification ********/
    pushSosNotifies(sos) {
        this.arrayOfSosSub.next(sos)
    }
}