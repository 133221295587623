import { Routes } from '@angular/router';
import { AuthGuard } from './core/services/auth-guard.service';
import { CompanyAuthGuard } from './core/services/company-auth-guard.service';
import { EmployeeAuthGuard } from './core/services/employee-auth-guard.service';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';

export const rootRouterConfig: Routes = [


  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/admin/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session' }
      },
      {
        path: 'employee',
        loadChildren: () => import('./modules/employee/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session' }
      },

    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/admin/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'Dashboard' }
      },
    
      // {
      //   path: 'salesreport',
      //   loadChildren: () => import('./modules/admin/sales-report/sales-report.module').then(m => m.SalesReportModule),
      //   data: { title: 'Sales-Report', breadcrumb: 'Sales-Report' }
      // },
      {
        path: 'cms',
        loadChildren: () => import('./modules/admin/cms/cms.module').then(m => m.CmsModule),
        data: { title: 'CMS', breadcrumb: 'CMS' }
      },
      {
        path: 'notification',
        loadChildren: () => import('./modules/admin/notification/notification.module').then(m => m.NotificationModule),
        data: { title: 'Notification', breadcrumb: 'Notification' }
      },
      {
        path: 'leads',
        loadChildren: () => import('./modules/admin/leads/leads.module').then(m => m.AppLeadsModule),
        data: { title: 'Leads', breadcrumb: 'leads' }
      },
      {
        path: 'dealer-details',
        loadChildren: () => import('./modules/admin/dealer-details/dealer-details.module').then(m => m.AppDealerDetailsModule),
        data: { title: 'Dealers', breadcrumb: 'Dealers' }
      },
      {
        path: 'customer-details',
        loadChildren: () => import('./modules/admin/customer-details/customer-details.module').then(m => m.AppCustomerDetailsModule),
        data: { title: 'Customers', breadcrumb: 'Customers' }
      },
      {
        path: 'commission-transaction',
        loadChildren: () => import('./modules/admin/commission-transaction/commission-transaction.module').then(m => m.AppCommissionTransactionModule),
        data: { title: 'Commission-Transaction', breadcrumb: 'Commission-Transaction' }
      },
      // {
      //   path: 'ratings-review',
      //   loadChildren: () => import('./modules/admin/ratings-review/ratings-review.module').then(m => m.AppRatingsReviewModule),
      //   data: { title: 'Ratings-Review', breadcrumb: 'Ratings-Review' }
      // },
      {
        path: 'waiting-payments',
        loadChildren: () => import('./modules/admin/waiting-payments/waiting-payments.module').then(m => m.AppWaitingPaymentsModule),
        data: { title: 'Waiting-Payments', breadcrumb: 'Waiting-Payments' }
      },
      {
        path: 'waiting-cod',
        loadChildren: () => import('./modules/admin/waiting-cod/waiting-cod.module').then(m => m.AppWaitingCodModule),
        data: { title: 'Waiting-COD', breadcrumb: 'Waiting-COD' }
      },
      {
        path: 'waiting-dispatch',
        loadChildren: () => import('./modules/admin/waiting-dispatch/waiting-dispatch.module').then(m => m.AppWaitingDispatchModule),
        data: { title: 'Waiting-Dispatch', breadcrumb: 'Waiting-Dispatch' }
      },
      // {
      //   path: 'dispatch-details',
      //   loadChildren: () => import('./modules/admin/dispatch-details/dispatch-details.module').then(m => m.AppDispatchDetailssModule),
      //   data: { title: 'Dispatch-Details', breadcrumb: 'Dispatch-Details' }
      // },

      {
          path: 'delivery-details',
          loadChildren: () => import('./modules/admin/delivery-details/delivery-details.module').then(m => m.AppDeliveryDetailssModule),
          data: { title: 'Delivery-Details', breadcrumb: 'Delivery-Details' }
       },
        
      {
        path: 'cancelled-details',
        loadChildren: () => import('./modules/admin/cancelled-details/cancelled-details.module').then(m => m.AppCancelledDetailssModule),
        data: { title: 'Cancelled-Details', breadcrumb: 'Cancelled-Details' }
      },
      {
        path: 'track-order',
        loadChildren: () => import('./modules/admin/track-order/track-order.module').then(m => m.AppTrackOrderModule),
        data: { title: 'Track-Order', breadcrumb: 'Track-Order' }
      },
      {
        path: 'product-catalogue',
        loadChildren: () => import('./modules/admin/catalog/catalog.module').then(m => m.AppCatalogModule),
        data: { title: 'Product Catalogue', breadcrumb: 'Product Catalogue' }
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./modules/admin/reports/reports.module").then(
            (m) => m.AppReportsModule
          ),
        data: {
          title: "Reports",
          breadcrumb: "Reports",
        },
      },
      {
        path: "reports/sales-report",
        loadChildren: () =>
          import("./modules/admin/reports/sales-report/sales-report.module").then(
            (m) => m.AppSalesReportModule
          ),
        data: {
          title: "Sales Report",
          breadcrumb: "Sales Report",
        },
      },
      {
        path: "reports/stock-report",
        loadChildren: () =>
          import("./modules/admin/reports/stock-report/stock-report.module").then(
            (m) => m.AppStockReportModule
          ),
        data: {
          title: "Stock Report",
          breadcrumb: "Stock Report",
        },
      },
      {
        path: "reports/payments",
        loadChildren: () =>
          import("./modules/admin/reports/payments/payments.module").then(
            (m) => m.AppPaymentsModule
          ),
        data: {
          title: "Payment Transaction Report",
          breadcrumb: "Payment Transaction Report",
        },
      },

      {
        path: "reports/dealerpayout-report",
        loadChildren: () =>
          import("./modules/admin/reports/dealerpayout-report/dealerpayout-report.module").then(
            (m) => m.AppDealerPayoutModule
          ),
        data: {
          title: "Dealer Payout Report",
          breadcrumb: "Dealer Payout Report",
        },
      },
     
      {
        path: 'settings',
        loadChildren: () => import('./modules/admin/settings/settings.module').then(m => m.AppSettingsModule),
        data: { title: 'Settings', breadcrumb: 'Settings' }
      },
      {
        path: 'payment-transaction',
        loadChildren: () => import('./modules/admin/payment/payment.module').then(m => m.AppPaymentModule),
        data: { title: 'Payment', breadcrumb: 'Payment' }
      },
      

    ]
  },

  {
    path: 'employee',
    component: AdminLayoutComponent,
    canActivate: [EmployeeAuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/employee/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Overview', breadcrumb: 'Overview' }
      },
    
      {
        path: 'enquiries',
        loadChildren: () => import('./modules/employee/enquiries/enquiries.module').then(m => m.EnquiriesModule),
        data: { title: 'Enquiries', breadcrumb: 'Enquiries' }
      },
     
      {
        path: 'leads',
        loadChildren: () => import('./modules/employee/leads/leads.module').then(m => m.AppLeadsModule),
        data: { title: 'Leads', breadcrumb: 'leads' }
      },
      {
        path: 'calender',
        loadChildren: () => import('./modules/employee/calender/calender.module').then(m => m.CalenderModule),
        data: { title: 'Calender', breadcrumb: 'calender' }
      },
      {
        path: 'catalog',
        loadChildren: () => import('./modules/employee/catalog/catalog.module').then(m => m.AppCatalogModule),
        data: { title: 'Catalog', breadcrumb: 'catalog' }
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./modules/employee/reports/reports.module").then(
            (m) => m.AppReportsModule
          ),
        data: {
          title: "Reports",
          breadcrumb: "Reports",
        },
      },
      {
        path: "reports/enquiry",
        loadChildren: () =>
          import("./modules/employee/reports/enquiry/enquiry.module").then(
            (m) => m.AppEnquiryModule
          ),
        data: {
          title: "Ledger",
          breadcrumb: "Ledger",
        },
      },
      {
        path: "reports/lead",
        loadChildren: () =>
          import("./modules/employee/reports/lead/lead.module").then(
            (m) => m.AppLeadModule
          ),
        data: {
          title: "Ledger",
          breadcrumb: "Ledger",
        },
      },
      {
        path: "reports/payments",
        loadChildren: () =>
          import("./modules/employee/reports/payments/payments.module").then(
            (m) => m.AppPaymentsModule
          ),
        data: {
          title: "Ledger",
          breadcrumb: "Ledger",
        },
      },
     
      {
        path: 'payment-transaction',
        loadChildren: () => import('./modules/employee/payment/payment.module').then(m => m.AppPaymentModule),
        data: { title: 'Payment', breadcrumb: 'Payment' }
      },
      {
        path: 'notification',
        loadChildren: () => import('./modules/employee/notification/notification.module').then(m => m.NotificationModule),
        data: { title: 'Notification', breadcrumb: 'Notification' }
      },

    ]
  },



  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

