import { IMenuItem } from "./navigation.model";


export let buywoodMenu: IMenuItem[] = [
  {
    module:['Dashboard'],
    name: "Dashboard",
    type: "link",
    state: "dashboard"
  },

  {
    module:['Product'],
    name: " Catalogue",
    type: "link",
    state: "product-catalogue"
  },

  {
    name: "Orders",
    type: "dropDown",
    module: ["Waiting Payments","Waiting Dispatch","Dispatch Details", "Cancelled Details"],
    state: "",
    sub: [
      { name: "Waiting COD", module: ["Waiting COD"], state: "waiting-cod" },
      { name: "Waiting Payments", module: ["Waiting Payments"], state: "waiting-payments" },
      { name: "Waiting Dispatch", module: ["Waiting Dispatch"], state: "waiting-dispatch" },
      // { name: "Dispatch Details", module: ["Dispatch Details"], state: "dispatch-details" },
      { name: "Delivery Details", module: ["Delivery Details"], state: "delivery-details" },
      { name: "Cancelled Details", module: ["Cancelled Details"], state: "cancelled-details" },
      { name: "Track Order", module: ["Track Order"], state: "track-order" },
    ],
  },

  {
    module:['Customer Details'],
    name: "Customers",
    type: "link",
    state: "customer-details"
  },

  {
    module:['Dealer Details'],
    name: "Dealers",
    type: "link",
    state: "dealer-details"
  },

  {
    module:['Commission Transaction'],
    name: "Transactions",
    type: "link",
    state: "commission-transaction"
  },
 
  {
    name: "CMS",
    type: "dropDown",
    module: ["Banner","Video","Certificate", "Greetings"],
    state: "",
    sub: [
      { name: "Banner", module: ["Banner"], state: "cms/banner" },
      { name: "Video", module: ["Video"], state: "cms/video" },
      { name: "Certificate", module: ["Certificate"], state: "cms/certificate" },
      { name: "Greetings", module: ["Greetings"], state: "cms/greeting" },

    ],
  },
  

  // {
  //   module:['Leads'],
  //   name: "Leads",
  //   type: "link",
  //   state: "leads"
  // },



  {
    name: "Reports",
    type: "dropDown",
    module: ["Sales Reports","Stock Reports","Payment Transaction Reports","Dealer Payout Reports"],
    state: "",
    sub: [
      { name: "Sales Report", module: ["Sales Reports"], state: "reports/sales-report" },
      { name: "Stock Report", module: ["Stock Reports"], state: "reports/stock-report" },
      { name: "Payment Transaction Report", module: ["Payment Reports"], state: "reports/payments" },
      { name: "Dealer Payout Report", module: ["Dealer Payout Reports"], state: "reports/dealerpayout-report"},
    ],
  },


  {
   
    name: "Settings",
    type: "link",
    module:['Wood purpose','COD','Category','Delivery Charge','Shipped Vendor','Consultation Fee','User','Role','Permission','Change Password','Activity Log'],
    state: "",
    sub: [
      { name: "Settings", type: 'link', module:['Brand'], state: "settings/brand" },
      { name: "Settings", type: 'link', module:['Category'], state: "settings/category-subcategory" },
      { name: "Settings", type: 'link', module:['COD'], state: "settings/cod" },
      { name: "Settings", type: 'link', module:['Wood purpose'], state: "settings/wood" },
      { name: "Settings", type: 'link', module:['Consultation Fee'], state: "settings/consultation-fees" },
      { name: "Settings", type: 'link', module: ['User'], state: "settings/users" },
      { name: "Settings", type: 'link', module: ['Role'], state: "settings/role" },
      { name: "Settings", type: 'link', module:['Permission'], state: "settings/role-permission" },
      { name: "Settings", type: 'link', module: ['Change Password'], state: "settings/change-password" },
      { name: "Settings", type: 'link', module:['Activity Log'], state: "settings/activity-logs" },
      { name: "Settings", type: 'link', module:['Delivery Charge'], state: "settings/delivery-charge" },
      { name: "Settings", type: 'link', module:['Shipped Vendor'], state: "settings/shipped-vendor" },
      { name: "Settings", type: 'link', module:['Ratings & Review'], state: "settings/ratings-review" },

    ],
  },


];

export let buywoodExpEmployeeMenu: IMenuItem[] = [
  {
    module:['Enquiry'],
    name: "Enquiries",
    type: "link",
    state: "employee/enquiries"
  },
]

export let buywoodEmployeeMenu: IMenuItem[] = [
  {
    module:['Enquiry'],
    name: "Enquiries",
    type: "link",
    state: "employee/enquiries"
  },
  
  {
    module:['Leads'],
    name: "Leads",
    type: "link",
    state: "employee/leads"
  },
]
