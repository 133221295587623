import { IEmployee } from './employee.interface';

export class Employee implements IEmployee {
    public id? : number;
    public name? : string;
    public email? : string;
    public token? : string;
    public userName? : string;
    public password? :string;
    public oldPassword? : string;
    public newPassword? : string;
    public confirmPassword? : string;
    public avatar? : string;
    public mobileNumber? :string;
    public status?: string;
    public roleName? : string;
    public roleId? : any;
    public mobile? : any;
    public skybind? : any = '';
    public otp : number;
    public roletype: string;

    public enquiryid?: string;
    public enquirycode?: string;
    public date?: string;
    public enquiry_creator_name?: string;
    public district?: string;
    public enquiry_creator_email?: string;
    public location?: string;
    public purposeName?: string;
    public purposeNotes?: string;
    public districtName?: string;
    public stateName?: string;
    public enquiry_creator_mobile?: string;
    public state?: string;
    public enquiry_creator_status?: string;
    public createdOn?: string;
    public updatedOn?: string;
    public fileType?: string;
    public fees?: string;
    public isConsult?: string;
    telefollowup?: string;
    telenotes?: string;
    telereminder: string;
    forwardedemployeeid: string;
    adminstatus: string;
    followup: any;
    notes: any;
    reminder: any;
  }
  