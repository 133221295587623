import { ApiService } from './api.service';
import { AuthGuard } from './auth-guard.service';
import { UserService } from './user.service';
import { ErrorHandlerService } from './error-handler.service';
import { HttpCacheService } from './http-cache.service';
import { LayoutService } from './layout.service';
import { NavigationService } from './navigation.service';
import { PreviousRouteService } from './previous-route.service';
import { RoutePartsService } from './route-parts.service';
import { ThemeService } from './theme.service';
import { ToastService } from './toast.service';
import { MatchMediaService } from './match-media.service';
import { LanguageService } from './language.service';
import { ConfigService } from './config.service';
import { PermissionService } from './permission.service';
import { RedirectService } from './redirect.service';
import { GenerateRouteService } from './generate-route.service';
import { MessagingService } from './messaging.service';
import { AppLoaderService } from './app-loader.service';
import { CompanyService } from './company.service';
import { CompanyAuthGuard } from './company-auth-guard.service';

export const serviceProvider = [
    ApiService, 
    AuthGuard,
    CompanyService,
    CompanyAuthGuard,
    AppLoaderService,
    MessagingService,
    GenerateRouteService,
    UserService,
    ErrorHandlerService,
    HttpCacheService,
    LayoutService,
    NavigationService,
    PreviousRouteService,
    RoutePartsService,
    ThemeService,
    ToastService,
    MatchMediaService,
    LanguageService,
    ConfigService,
    PermissionService, 
    RedirectService,
];