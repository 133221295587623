
import { Injectable } from '@angular/core';
import { IChildItem, IMenuItem } from "../models/navigation.model";
import { PORTALTYPE } from "../helpers/enum.helper";
import { decodedToken } from '../helpers/token.helper';
import { get } from "lodash";
import { buywoodEmployeeMenu, buywoodExpEmployeeMenu, buywoodMenu} from '../models';

@Injectable({
    providedIn: 'root'
})

export class PermissionService {

    menuSortList: IMenuItem[] = [];
    permissions: Object;
    roleId: number = 0;
    portalType: string = '';

    constructor() {

    }

    hasPermission(moduleList: Array<any>, actionList?: Array<any>): boolean {
        this.autoInit();
        // console.log(this.roleId,'this is the role id');
        

        return true;

      /*  For Parent Menu
        if (this.portalType === PORTALTYPE.GYM) {
            return true;
        }

          if (this.roleId <= 0) {
                return false;
            }

        if (this.roleId > 0) {
            return true;
        }

        if (!this.permissions) {
            return false;
        }

        if (!actionList) {
            return this.isModuleHasAction(this.permissions, moduleList);
        }

        if (actionList?.length > 0) {
            return this.isActionFound(this.permissions, moduleList, actionList);
        }

        return false; */
    }

    isModuleHasAction(permList: Object, moduleList: Array<any>): boolean {
        // let admits = moduleList.filter(value => {
        //     if (permList.hasOwnProperty(value)) {
        //         return this.getValueByKey(permList, value).length > 0;
        //     }
        // });
        return true;
    }

    isActionFound(permList: Object, moduleList: Array<any>, searchList: Array<any>): boolean {
        // let admits = moduleList.filter(value => {
        //     if (permList.hasOwnProperty(value)) {
        //         let moduleActions = this.getValueByKey(permList, value);
        //         let permittedAction = moduleActions.filter(maction => {
        //             return searchList.indexOf(maction) !== -1;
        //         });
        //         return permittedAction.length > 0;
        //     }
        // });
        return true;
    }

    init(roleId: number, portalType: string, permissions: Object) {
        this.roleId = roleId;
        this.portalType = portalType;
        this.permissions = Object.keys(permissions).length > 0 ? permissions : {};
    }

    autoInit() {
        const token = decodedToken();
        this.portalType = get(token, 'portal') ?? '';
        this.roleId = get(token, 'acl_role_id') ?? '';
        this.permissions = get(token, 'permission') ?? {};
    }

    // get authMenu(): IMenuItem[] {
    //     if ((this.roleId == 3 && this.portalType == PORTALTYPE.GYM) || this.portalType == PORTALTYPE.DIETITIAN || this.portalType == PORTALTYPE.PHYSIOTHERAPIST) {
    //         let menu: IMenuItem[] = [];
    //         this.menuSortList = this.menu;
    //         menu = this.menuSortList.filter(data => {
    //             return (data.module?.length > 0 && this.hasPermission(data.module));
    //         });
    //         return menu.map(x => {
    //             if (x?.sub) { x.sub = this.getAuthSubmMenu(x.sub); }
    //             return x;
    //         });
    //     }
    // }


    get authMenu(): IMenuItem[] {
        const token = decodedToken();
        console.log(token, "token")
        console.log(this.roleId, "role id", this.portalType, PORTALTYPE.COMPANY , "enter----->")
        if ((this.roleId == 1 && this.portalType == PORTALTYPE.ADMIN) || this.portalType == PORTALTYPE.COMPANY || this.portalType == PORTALTYPE.EMPLOYEEE) {
            return this.menu;
        }
        let menu: IMenuItem[] = [];
        this.menuSortList = this.menu;
        menu = this.menuSortList.filter(data => {
            return (data.module?.length > 0 && this.hasPermission(data.module));
        });
        return menu.map(x => {
            if (x?.sub) { x.sub = this.getAuthSubmMenu(x.sub); }
            return x;
        });
    }

    getAuthSubmMenu(ichild: IChildItem[]): IChildItem[] {
        let childMenu: IChildItem[] = [];
        childMenu = ichild.filter((data) => {
            return data.module?.length > 0 && this.hasPermission(data.module);
        });
        return childMenu.map((x) => {
            if (x?.sub) {
                x.sub = this.getAuthChildSubMenu(x.sub);
            }
            return x;
        });
    }

    getAuthChildSubMenu(ichild: IChildItem[]): IChildItem[] {
        return ichild.filter((data) => {
            return data.module?.length > 0 && this.hasPermission(data.module);
        });
    }

    get menu(): IMenuItem[] {
        let menuList: IMenuItem[] = [];
        let token = decodedToken();
        if (this.portalType == PORTALTYPE.ADMIN) {
            menuList = buywoodMenu;
        } 
        else if(this.portalType == PORTALTYPE.EMPLOYEEE && token?.employee_type == '1'){
            menuList = buywoodExpEmployeeMenu;
        }
        else if(this.portalType == PORTALTYPE.EMPLOYEEE && token?.employee_type == '2'){
            menuList = buywoodEmployeeMenu;
        }
        else{
            menuList = buywoodMenu;
        }
        return menuList;
    }

    getValueByKey(object: Object, searchKey: string): Array<any> {
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                if (key === searchKey) {
                    return object[key] as Array<any>;
                }
            }
        }

        return [];
    }

}







