import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from 'app/core/services/api.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  readonly notificationList$: EventEmitter<any> = new EventEmitter<any>();
  constructor(private modelService: ApiService) { }


  getAllNotification(): Observable<any> {
    return this.modelService.get('notification')
  }

  setReadStatus(key): Observable<any> {
    return this.modelService.post('readNotification', key);
  }

  setNotificationList(property: any, storeProp: boolean = false) {
    if (storeProp) {
      this.notificationList$.emit(storeProp);
    }
  }


}
